<template>
  <div
    class="cart"
    id="cart"
    :class="[
      { 'empty' : storeCart.items && storeCart.items.length === 0 }
    ]"
  >

    <!-- TEST ----- scan START -->
    <input
      type="text"
      class="test-input-terminal hidden"
      :placeholder="'Article EAN'"
      v-model="articleEANTest"
      v-on:keyup.enter="onEnterArticle"
    />
    <!-- TEST ----- scan END -->

    <h1
      v-if="storeCart.items && storeCart.items.length > 0"
      v-html="$t('terminal.cart.main.headline')"
      class="main-headline"
    ></h1>

    <!-- cart:empty START -->
    <empty-cart
      v-if="storeCart.items && storeCart.items.length === 0"
      :categories="categories"
    />
    <!-- cart:empty END -->

    <!-- cart:products START -->
    <products
      :products="storeCart.items"
      :cart="cart"
      :increase="increase"
      :decrease="decrease"
      :priceFormat="priceFormat"
      v-else
    />
    <!-- cart:products END -->

    <!-- cart:actions START -->
    <actions
      :class="[
        { 'dialog-active' : dialogVisibleHelper },
        { 'barcode-active' : barcodeActive }
      ]"
      :products="storeCart"
      :categories="categories"
      :priceFormat="priceFormat"
      :resetStoreCart="resetStoreCart"
      :activeCategory="activeCategory"
      :dialogOpen="dialogOpen"
      :total="total"
      :addProduct="addProduct"
      :isBarcodeActive="isBarcodeActive"
      :done="done"
    />
    <!-- cart:actions END -->

    <!-- cart:modal:products START -->
    <dialog-products
      :title="dialogTitle"
      :headline="dialogHeadline"
      :description="dialogDescription"
      :type="dialogContentType"
      :dialogVisible="dialogVisible"
      :dialogClose="dialogClose"
      :products="storeCart ? storeCart : {}"
      :filteredProducts="filteredProducts"
      :addProductIDQuantity="addProductIDQuantity"
      :removeProduct="removeProduct"
    />
    <!-- cart:modal:products END -->

    <!-- confirm:modal START -->
    <dialog-products-choices
      :loadingVisible="productsChoicesVisible"
      :headline="productsChoicesHeadline"
      :excerpt="productsChoicesExcerpt"
      :open="openChoicesDialog"
      :close="closeChoicesDialog"
      :confirm="confirmChoicesDialog"
      :data="productsChoices"
      :selectedOption="selectedOption"
    />
    <!-- confirm:modal END -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'cart',
  components: {
    EmptyCart: () => import('../components/cart/Empty.vue'),
    Products: () => import('../components/cart/Products.vue'),
    Actions: () => import('../components/cart/Actions.vue'),
    DialogProducts: () => import('../components/shared/dialog/Products.vue'),
    DialogProductsChoices: () => import('../components/shared/dialog/ProductsChoices.vue'),
  },
  data() {
    return {
      isScrolledMoreThan: false,
      lastScrollTop: 0,

      barcodeActive: false,

      /**
       * Box
       */
      box: null,
      boxToken: localStorage.getItem('boxToken') ? localStorage.getItem('boxToken') : 'undefined',
      boxLang: localStorage.getItem('boxLang') ? localStorage.getItem('boxLang') : 'undefined',

      /**
       * Filter
       */
      filterCat: '',

      /**
       * Dialog
       */
      dialogVisibleHelper: false,
      dialogVisible: false,
      dialogTitle: this.$t('terminal.cart.dialog.cart.title'),
      dialogHeadline: this.$t('terminal.cart.dialog.cart.headline'),
      dialogDescription: this.$t('terminal.cart.dialog.cart.description'),
      dialogContentType: 'manual',

      selectedOption: '',
      productsChoicesVisible: false,
      productsChoicesHeadline: this.$t('terminal.cart.dialog.cart.select'),
      productsChoicesExcerpt: this.$t('terminal.cart.dialog.cart.format'),
      productsChoices: [],

      /**
       * Scan
       */
      articleEAN: '',
      articleEANTest: '',

      /**
       * Categories
       */
      categories: [],
      allProducts: [],
      tempCategories: [],
    };
  },
  // beforeRouteLeave(to) {
  //   if (to.path === '/') {
  //     window.location.assign('/');
  //   }
  // },
  async created() {
    this.allProducts = await this.FETCH_PRODUCTS();
    if (this.allProducts) {
      this.allProducts.forEach((product) => {
        product.articleGroups.forEach((cat) => {
          this.tempCategories.push(cat);
        });
      });

      // fetch categories
      if (this.tempCategories) {
        this.categories = this.tempCategories
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((v, i, a) => a.findIndex((t) => (t.id === v.id)) === i);
      }

      // if boxToxen then fetch box
      if (this.boxToken !== 'undefined' && this.boxLang !== 'undefined') {
        this.box = await this.FETCH_BOX();
      }

      if (this.$route.query.screensaverean) {
        this.addProduct(this.$route.query.screensaverean);

        setTimeout(() => {
          this.$router.replace({ query: null });
        }, 750);
      }
    }

    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('scroll', this.scrollHandler);
  },
  computed: {
    ...mapGetters({
      storeCart: 'storeCart',
    }),

    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }

      return this.$store.state.storeCart;
    },

    total() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.sumTotal;
      }
      return 0;
    },

    filteredProducts() {
      if (this.filterCat) {
        const { products } = this.$store.getters;
        return products
          .filter((p) => p.articleGroups
            .some((a) => a.id === Number(this.filterCat)));
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      'addItem',
      'addItemID',
      'removeItem',
      'updateItem',
      'clearCart',
      'setBox',
    ]),

    async FETCH_PRODUCTS() {
      await this.$store.dispatch('setProducts');
      return this.$store.getters.products;
    },

    async FETCH_BOX() {
      await this.setBox({
        payload: {
          token: this.boxToken,
        },
      });
      return this.$store.getters.box;
    },

    onEnterArticle() {
      // Q6JBIqfnUEFhshF53rIuEH7mdngfIUP6bsCf0Dl8s9qpdNfAfg84l9Id5qb9pUhn
      if (this.articleEANTest) {
        this.addProduct(this.articleEANTest);
        setTimeout(() => {
          this.articleEANTest = '';
        }, 350);
      }
    },

    keyDownHandler(e) {
      // 4001724819004 // 4001686315354 // 2704493005006
      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';

      // scan articles
      if (this.articleEANTest === '') {
        this.articleEAN += char;

        if (e.keyCode === 13) {
          const ean = this.articleEAN;
          this.articleEAN = '';
          this.addProduct(ean);
          // setTimeout(() => {
          //   this.articleEAN = '';
          // }, 350);
        }
      }
    },

    async addProduct(EAN, decision) {
      this.productsChoices = [];
      if (EAN) {
        let payload = {};

        if (decision === 1) {
          payload = {
            ean: EAN,
            quantity: '',
            decision: 1,
          };
        } else {
          payload = {
            ean: EAN,
            quantity: '',
          };
        }
        const addedItem = await this.addItem({
          payload,
        });

        if (addedItem.status === 300) {
          this.productsChoices = addedItem.data;

          this.openChoicesDialog();
        } else if (addedItem === true) {
          this.success();
          setTimeout(() => {
            this.scrollTo('cart');
          }, 350);

          // delete created notification dom element
          setTimeout(() => {
            if (document.querySelector('.el-notification')) {
              const elem = document.querySelector('.el-notification');
              elem.remove();
            }
            console.clear();
          }, 1750);
        } else {
          this.error();
        }
      }
    },

    async addProductQuantity(EAN, q) {
      if (EAN && q) {
        const addedItem = await this.addItem({
          payload: {
            ean: EAN,
            quantity: q,
          },
        });
        if (addedItem) {
          this.success();
          setTimeout(() => {
            this.scrollTo('cart');
          }, 350);

          // delete created notification dom element
          setTimeout(() => {
            if (document.querySelector('.el-notification')) {
              const elem = document.querySelector('.el-notification');
              elem.remove();
            }
            console.clear();
          }, 1750);
        } else {
          this.error();

          // delete created notification dom element
          setTimeout(() => {
            if (document.querySelector('.el-notification')) {
              const elem = document.querySelector('.el-notification');
              elem.remove();
            }
            console.clear();
          }, 1750);
        }
      }
    },

    async addProductIDQuantity(id, q) {
      if (id && q) {
        const addedItem = await this.addItemID({
          payload: {
            id,
            quantity: q,
          },
        });
        if (addedItem) {
          this.success();
          setTimeout(() => {
            this.scrollTo('cart');
          }, 350);

          // delete created notification dom element
          setTimeout(() => {
            if (document.querySelector('.el-notification')) {
              const elem = document.querySelector('.el-notification');
              elem.remove();
            }
            console.clear();
          }, 1750);
        } else {
          this.error();

          // delete created notification dom element
          setTimeout(() => {
            if (document.querySelector('.el-notification')) {
              const elem = document.querySelector('.el-notification');
              elem.remove();
            }
            console.clear();
          }, 1750);
        }
      }
    },

    removeProduct(id) {
      if (id) {
        const productCart = this.storeCart.items.find((i) => i.articleID === id);
        const productCartID = productCart.id;
        this.removeItem(productCartID);
      }
    },

    increase(product) {
      if (product) {
        const p = product;
        p.quantity += 1;
        this.updateItem({
          id: Number(p.id),
          quantity: p.quantity,
        });
      }
    },

    decrease(product) {
      if (product) {
        if (product.quantity > 1) {
          const p = product;
          p.quantity -= 1;
          this.updateItem({
            id: Number(p.id),
            quantity: p.quantity,
          });
        } else {
          this.removeItem(product.id);
        }
      }
    },

    scrollTo(elem) {
      const element = document.getElementById(elem);
      const top = element.scrollHeight + 208;
      window.scrollTo(0, top);
      // const container = this.$el;
      // container.scrollTop = container.scrollHeight;
    },

    async resetStoreCart() {
      // reset store cart
      await this.clearCart();

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      await this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');
          }
        });

      // redirect to home + reload app
      window.location.assign('/');
    },

    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('boxLang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },

    dialogOpen() {
      this.dialogVisibleHelper = true;
      setTimeout(() => {
        this.dialogVisible = true;
      }, 125);
    },

    dialogClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.dialogVisibleHelper = false;
      }, 125);
    },

    openChoicesDialog() {
      this.productsChoicesVisible = true;
      this.selectedOption = '';
    },

    closeChoicesDialog() {
      this.productsChoicesVisible = false;
    },

    confirmChoicesDialog(prod) {
      const decision = 1;

      this.addProduct(prod, decision);

      this.productsChoicesVisible = false;
    },

    activeCategory(catID, catName) {
      this.filterCat = catID;
      this.dialogTitle = catName;
    },

    isBarcodeActive() {
      this.barcodeActive = !this.barcodeActive;
    },

    done() {
      if (this.box.hasAppCheckIn === 1 || this.box.hasSmsCheckIn === 1
        || this.box.hasCardCheckin === 1) {
        this.$router.push('/cart/app-payment');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.total <= 0) {
          this.error(this.$t('terminal.cart.payout.notpossible'), 5000, true);
        } else {
          this.$router.push('/cart/summary');
        }
      }
    },

    success() {
      this.$notify({
        message: this.$t('cart.product.success'),
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 1500,
      });
    },

    error(message, duration, showClose) {
      if (message) {
        this.$notify({
          message,
          type: 'error',
          position: 'bottom-right',
          showClose: showClose || false,
          duration: duration || 1500,
        });
      } else {
        this.$notify({
          message: this.$t('cart.product.failed'),
          type: 'error',
          position: 'bottom-right',
          showClose: showClose || false,
          duration: duration || 1500,
        });
      }
    },

    scrollHandler() {
      const scrolledElement = document.documentElement;
      const { scrollTop } = scrolledElement;

      // Check if scroll state has changed significantly
      if (Math.abs(scrollTop - this.lastScrollTop) > 5) {
        // Update the last scroll position
        this.lastScrollTop = scrollTop;

        // Check if scrolled more than 124 pixels and flag is false
        if (scrollTop > 112 && !this.isScrolledMoreThan) {
          // Reverse logic here
          document.querySelector('table.table-container').classList.add('padding-top');
          document.querySelector('thead').classList.add('fixed');
          // console.log('Scrolled more than 124 pixels!');
          this.isScrolledMoreThan = true;
        }

        // Check if scrolled less than or equal to 124 pixels and flag is true
        if (scrollTop <= 112 && this.isScrolledMoreThan) {
          // Reset logic if needed
          document.querySelector('table.table-container').classList.remove('padding-top');
          document.querySelector('thead').classList.remove('fixed');
          // console.log('Scrolled less than or equal to 124 pixels.');
          this.isScrolledMoreThan = false;
        }
      }
    },
  },
  mounted() {
    // dispatch store cart
    // console.log(this.$store.getters.storeCart);
    if (this.$store.getters.storeCart) {
      this.$store.dispatch('setCart');
    }
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>
